<template>
  <div
    class="c-container"
    @mousemove="hasActivatedDropdown = true"
  >
    <HeaderDropdown
      ref="dropdown"
      @touchstart.native="triggerHref = 'javascript:;'"
      @mouseleave-content="initCurrentJob"
      @touch-trigger="hasActivatedDropdown = true"
    >
      <!-- // NOTE: NuxtLinkだとhrefに 「/javascript:;」 と設定されてしまうのでaタグに変更 -->
      <a
        :href="triggerHref"
        class="c-trigger"
        slot="trigger"
      >
        <CoconalaIcon
          v-if="isBusinessHeader"
          color="ds2Gray700"
          name="user"
          size="12px"
        />
        {{ isBusinessHeader ? '専門家を探す' : $t('header.searchMenuJob') }}
        <CoconalaIcon
          name="chevron-down"
          color="ds2Gray600"
          size="8px"
        />
      </a>
      <ClientOnly>
        <div
          v-if="!hasMountedDropdown"
          class="c-loading"
        >
          <DLoading
            :is-full-page="false"
            active
          />
        </div>
        <SearchMenuJobLv1
          v-if="hasActivatedDropdown"
          :jobs="displayJobs"
          :is-business-before-login="headerType === HEADER_TYPE.BUSINESS_BEFORE_LOGIN"
          @change-job="changeCurrentJob($event)"
          @mounted="hasMountedDropdown = true"
          v-intersect-translate.once
        />
        <SearchMenuJobLv2
          v-for="job in displayJobs"
          v-show="currentJobId === job.id"
          :job="job"
          :is-business-before-login="headerType === HEADER_TYPE.BUSINESS_BEFORE_LOGIN"
          :key="`searchMenuJobLv2-${job.id}`"
          @change-sub-job="changeCurrentSubJob($event)"
          v-intersect-translate.once
        />
        <SearchMenuJobLv3
          v-if="displayJobTypes.length"
          :job-types="displayJobTypes"
          :sub-job-id="currentSubJobId"
          :sub-job-title="currentSubJobTitle"
          :is-business-before-login="headerType === HEADER_TYPE.BUSINESS_BEFORE_LOGIN"
        />
      </ClientOnly>
    </HeaderDropdown>
  </div>
</template>
<script>
import DLoading from '~/components/atoms/DS2/DLoading'
import HeaderDropdown from './HeaderDropdown'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SearchMenuJob',
  components: {
    DLoading,
    HeaderDropdown,
    SearchMenuJobLv1: () => import('./SearchMenuJobLv1'),
    SearchMenuJobLv2: () => import('./SearchMenuJobLv2'),
    SearchMenuJobLv3: () => import('./SearchMenuJobLv3')
  },
  props: {
    jobs: {
      type: Array,
      default() {
        return []
      }
    },
    initialJobId: {
      type: Number,
      default: null
    },
    initialSubJobId: {
      type: Number,
      default: null
    },
    headerType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentJobId: this.initialJobId,
      currentSubJobId: this.initialSubJobId,
      currentSubJobTitle: '',
      hasActivatedDropdown: false,
      hasMountedDropdown: false,
      $dropdownContent: null,
      triggerHref: '/users/'
    }
  },
  computed: {
    ...mapState('constants', ['HEADER_TYPE']),
    ...mapGetters('master', ['businessJobs']),
    displayJobs() {
      return this.isBusinessHeader || this.isMzhcHeader ? this.businessJobs : this.jobs
    },
    displayJobTypes() {
      if (!this.currentJobId || !this.currentSubJobId) {
        return []
      }
      const subJobs = this.displayJobs.find(job => job.id === this.currentJobId)?.subJobs || []
      return subJobs.find(job => job.id === this.currentSubJobId)?.subJobs || []
    },
    isBusinessHeader() {
      return (
        this.headerType === this.HEADER_TYPE.BUSINESS ||
        this.headerType === this.HEADER_TYPE.BUSINESS_BEFORE_LOGIN
      )
    },
    isMzhcHeader() {
      return this.headerType === this.HEADER_TYPE.MZHC
    }
  },
  created() {
    if (this.headerType === this.HEADER_TYPE.BUSINESS_BEFORE_LOGIN) {
      this.triggerHref = '/users/?is_business=1'
    }
  },
  methods: {
    initCurrentJob() {
      this.currentJobId = null
      this.currentSubJobId = null
      this.currentSubJobTitle = ''
    },
    changeCurrentJob(jobId) {
      this.currentJobId = jobId
      this.currentSubJobId = null
      this.currentSubJobTitle = ''
    },
    changeCurrentSubJob(subJob) {
      this.currentSubJobId = subJob.id
      this.currentSubJobTitle = subJob.title
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .c-headerDropdown {
  &_content {
    display: flex;
    overflow-y: auto;
  }
  &-item,
  .has-link {
    &.-title {
      padding-top: 3px;
      padding-bottom: 3px;
      font-size: 16px;
    }
  }
}
.c-trigger {
  @include link-effect-background(4px);

  display: inline-block;
  color: $ds2-color-gray-900;
  letter-spacing: 0.02em;
  font-size: 13px;

  .-user {
    vertical-align: -1px;
  }
}
.c-loading {
  min-height: 120px;
  width: 100%;
  background-color: $ds2-color-white;
}
</style>
